<template>
  <div class="text-left">
    <BaseHeader :title="'Styles'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>
      <div class=" my-2 ">

        


        <div class="card orderlisting--card py-2">
        <section class=" order-listing-header-main p-3  ">  
          <div class=" d-flex align-items-center justify-content-between header-elements-inline pb-0">
            <!-- <h2 class="card-title text--dark--custom font-weight-black text-capitalize">
               {{ $route.params.status }} 
            </h2> -->
            <base-select-site
              class="p-2"
              @siteSelected="getStyles"
            ></base-select-site>
            <div class="header-elements">
              <button 
                color="primary"
                class="my-auto btn btn-add ml-2"
                size="small"
                @click.stop="crudAction"
                >New</button
              >
            </div>
          </div>
        
        </section>

        <div class=" mx-0  pref-table-custom ">
        
          <div  class=" order-listing-header    row  p-3 mx-sm-0 mx-1 ">  
            <div class="col-2 text-left text--dark--custom "> ID </div>
            <div class="col-3 text-left text--dark--custom ">Desc</div>
            <div class="col-2 text-left text--dark--custom">Amount</div>
            <div class="col-2 text-left text--dark--custom">Is Default</div>
            <div class="col-2 text-left text--dark--custom"> Status</div>
            <div class="col-1 text-left text--dark--custom">Action</div>
        </div>
      

        <div v-if="styles.length > 0" class="">
        <div v-for="(item, index) in styles" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
            <div class="col-2 d-flex align-items-start justify-content-start text-left">	
              <span style="font-weight:500!important" class="px-2"
                >{{ item.id || "" }}</span>
              
            </div>
            <div class="col-3 text-left">
              <div style="font-weight:500!important" class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.description || "" }}</div>
            </div>
            <div class="col-2 text-left">{{ item.amount || "" }}</div>
            <div style="font-weight:500!important" class="col-2 text-left">  
              <v-chip v-if="item.default !== '0'" small color="primary"
              >Default</v-chip
            >
              
            </div>
            <div style="font-weight:500!important" class="col-2 text-left">  
              <v-chip :color="item.status ? 'primary' : 'error'" small outlined>{{
                item.status ? "Active" : "Inactive"
              }}</v-chip>
              
            </div>
            
            <div class="col-1 text-left  ">
              <v-btn
                elevation="0"
                class="white--text"
                @click="
                  editMode(item);
                  edit = true;
                  $store.state.dialog = true;
                "
                fab
                x-small
              >
              <v-icon scale="1.3" name="eye" color="black" />

              </v-btn>
            </div>
        </div>
        </div>

        </div>
        <div class="p-3 d-flex align-items-center justify-content-center w-100 "  v-if="styles.length < 1">
          
              <strong class="order_empty  text-center" >No Styles.</strong>
          
        </div>
      </div>



      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="stylesForm.busy">
          <v-card-title class="text-h5">
            {{ edit ? `Update ${stylesForm.level || ""}` : "New style" }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="stylesForm"
              @submit.prevent="createWriterLevel"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      outlined
                      label="Description"
                      name="description"
                      type="text"
                      v-model="stylesForm.description"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="stylesForm"
                            class="v-messages theme--light error--text"
                            field="description"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      outlined
                      label="Amount"
                      name="amount"
                      v-model="stylesForm.amount"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="stylesForm"
                            class="v-messages theme--light error--text"
                            field="amount"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-switch
                      v-model="stylesForm.default"
                      label="Is Default"
                      color="primary"
                      :value="stylesForm.default"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="4" v-show="edit">
                    <v-switch
                      v-model="stylesForm.status"
                      label="Is Active"
                      color="primary"
                      :value="stylesForm.status"
                      class="mt-0"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="stylesForm.busy"
                    type="submit"
                    color="primary"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Styles",
  computed: {
    ...mapState("styles", ["stylesForm", "loading", "styles", "headers"]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("styles", ["getStyles"]),
    // ...mapActions("clients", ["getClients"]),
    createWriterLevel() {
      this.stylesForm.default = this.stylesForm.default ? true : false;
      this.stylesForm.status = this.stylesForm.status ? true : false;
      this.edit
        ? this.stylesForm
            .put(`${this.$baseUrl}/Setups/Styles/${this.$store.state.site_id}`)
            .then((res) => {
              this.$store.state.dialog = false;
             

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `style updated successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.getStyles();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
             

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating style`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            })
        : this.stylesForm
            .post(`${this.$baseUrl}/Setups/Styles/${this.$store.state.site_id}`)
            .then((res) => {
              this.$store.state.dialog = false;
              

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `style created successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.getStyles();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating style`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            });
    },

    editMode(style) {
      this.edit = true;
      this.stylesForm.reset();
      this.$store.state.dialog = true;
      Object.assign(this.stylesForm, { ...style });
      this.stylesForm.default = this.stylesForm.default !== "0" ? true : false;
      this.stylesForm.active = this.stylesForm.active !== "0" ? true : false;
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.stylesForm.reset();
        this.$store.state.dialog = true;
      } else {
        

        this.$swal({
              icon: 'error',
              title: 'Error',
              text: "Please select a site to proceed",
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
      }
    },
  },
  async mounted() {
    // this.$store.state.site_id = this.$route.params.siteid

    if (this.$store.state.site_id) {
      await this.getStyles();
    }
  },
};
</script>
